// Courier
export const ALTO_PHARMACY_ID = 'phr_01G9CM93X1NFP1C9H9K50DPKHX';
// Mail Order
export const CUREXA_PHARMACY_ID = 'phr_01GCA54GVKA06C905DETQ9SY98';
export const CAREPOINT_PHARMACY_ID = 'phr_01GA9HPVBVJ0E65P819FD881N0';
export const AMAZON_PHARMACY_ID = 'phr_01GA9HPV5XYTC1NNX213VRRBZ3';
export const GOGOMEDS_PHARMACY_ID = 'phr_01HQVGZ1PTGVCD9EVKZVB18WP9';
export const REDBOX_PHARMACY_ID = 'phr_01HQQ1DHTW6ZQ19W5R3G22WQ41';
export const TAILORMADE_PHARMACY_ID = 'phr_01HNR6THE9BE7XBFQMZN7ZEFGA';
export const EMPOWER_PHARMACY_ID = 'phr_01HQD5F3C5M88JVNZWVVK9DTAE';
export const HONEYBEE_PHARMACY_ID = 'phr_01GA9HPXNE3TGEWPK91YY8Z4TS';
export const TRUEPILL_PHARMACY_ID = 'phr_01HT0PDJN87W3QA7YD2ZT5Q0DD';
export const COSTCO_PHARMACY_ID = 'phr_01GA9HPWTQ75YNJGFD505X5C4J';
export const INNOVASCRIPT_PHARMACY_ID = 'phr_01HSC9J1KM4EYZ7ZZ74YT1EKY2';
export const AMBROSIA_PHARMACY_ID = 'phr_01GA9HPX3N3Y0RDQV1RMP9F14J';
export const WOMENS_INTERNATIONAL_PHARMACY_ID = 'phr_01J0XD00F5NT6DBT7M97SX4FSS';
export const REDROCK_SPRINGVILLE_PHARMACY_ID = 'phr_01GA9HPXHHRTQS0ZQ36XHND9AW';
export const REDROCK_STGEORGE_PHARMACY_ID = 'phr_01GA9HPXM8SCGXVWCM4Q853B2X';
export const FOOTHILLS_PHARMACY_ID = 'phr_01J3GGVK67FDQSZJ7RX3WXFSCP';
